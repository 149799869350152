import { array, boolean, InferType, object, string } from 'yup';
import { UseFormProps } from 'react-hook-form/dist/types';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    CAPABILITY_HANDLER,
    CAPABILITY_INPUT,
    CAPABILITY_INSTANCE,
    CAPABILITY_NAME,
    CAPABILITY_OUTPUT,
    CAPABILITY_SUMMARY,
    HAS_NO_CAPABILITY_INPUT,
    HAS_NO_CAPABILITY_INSTANCE,
    HAS_NO_CAPABILITY_OUTPUT,
    TAGS,
} from '../constants/form-constants';
import { isValidJSON } from '../../../../common/utils';

const CreateCapabilitySchema = object({
  [CAPABILITY_NAME]: string().required().min(3).max(100).label('Name'),
  [CAPABILITY_HANDLER]: string().required().matches(RegExp('^\\S+::\\S+::\\S+$'), {
      message: 'Handler is not in valid format.',
  }).label('Handler'),
  [TAGS]: array().max(10).label('Tags'),
  [CAPABILITY_SUMMARY]: string().min(10).max(500).label('Summary'),
  [CAPABILITY_INSTANCE]: string().nullable().test(isValidJSON).label('Instance'),
  [HAS_NO_CAPABILITY_INSTANCE]: boolean()
      .when(CAPABILITY_INSTANCE, {
          is: (val: string) => !val,
          then: boolean().equals([true], 'Tick the checkbox in case instance is not required'),
          otherwise: boolean().equals([false], 'Untick the checkbox in case input is required'),
      })
      .label('Instance'),
  [CAPABILITY_INPUT]: string().nullable().test(isValidJSON).label('Input'),
  [HAS_NO_CAPABILITY_INPUT]: boolean()
    .when(CAPABILITY_INPUT, {
      is: (val: string) => !val,
      then: boolean().equals([true], 'Tick the checkbox in case input is not required'),
      otherwise: boolean().equals([false], 'Untick the checkbox in case input is required'),
    })
    .label('Input parameters'),
  [CAPABILITY_OUTPUT]: string().nullable().test(isValidJSON).label('Output'),
  [HAS_NO_CAPABILITY_OUTPUT]: boolean()
    .when(CAPABILITY_OUTPUT, {
      is: (val: string) => !val,
      then: boolean().equals([true], 'Tick the checkbox in case output is not required'),
      otherwise: boolean().equals([false], 'Untick the checkbox in case output is required'),
    })
    .label('Output'),
});

export type CreateCapabilitySchemaType = InferType<typeof CreateCapabilitySchema>;

export const CreateCapabilityFormProps: UseFormProps<CreateCapabilitySchemaType, any> = {
  mode: 'onSubmit',
  reValidateMode: 'onChange',
  resolver: yupResolver(CreateCapabilitySchema),
};
