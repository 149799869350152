import { Container, Header, SpaceBetween } from '@cloudscape-design/components';
import React, { useState } from 'react';

import { CODE_EDITOR_I18N_STRINGS } from '../../../../common/constants/string-constants';
import { getBlockTagsListForSelection, loadAce } from '../../../../common/utils';
import { CheckboxFormField } from '../../../../components/polaris/CheckboxFormField';
import { CodeEditorFormField } from '../../../../components/polaris/CodeEditorFormField';
import InputFormField from '../../../../components/polaris/InputFormField';
import MultiselectFormField from '../../../../components/polaris/MultiselectFormField';
import TextareaFormField from '../../../../components/polaris/TextAreaFormField';
import {
  CAPABILITY_HANDLER,
  CAPABILITY_INPUT,
  CAPABILITY_INSTANCE,
  CAPABILITY_NAME,
  CAPABILITY_OUTPUT,
  CAPABILITY_SUMMARY,
  HAS_NO_CAPABILITY_INPUT,
  HAS_NO_CAPABILITY_INSTANCE,
  HAS_NO_CAPABILITY_OUTPUT,
  TAGS,
} from '../constants/form-constants';

export default function MyBlockFunctionRegisterContainer() {
  const [ace, setAce] = useState({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    loadAce()
      .then((ace) => setAce(ace))
      .finally(() => setLoading(false));
  }, []);

  return (
    <SpaceBetween direction="vertical" size="xl">
      <Container header={<Header variant="h2">Basic information</Header>}>
        <SpaceBetween direction="vertical" size="l">
          <InputFormField
            name={CAPABILITY_NAME}
            label="Name"
            description="Provide a descriptive name for the function."
            ariaRequired={true}
          />
          <InputFormField
            name={CAPABILITY_HANDLER}
            label="Handler"
            description="Handler for the function to invoke."
            ariaRequired={true}
            constraintText="Must be of format - AssemblyName::Namespace.ClassName::FunctionName"
          />
          <MultiselectFormField
            name={TAGS}
            label={
              <span>
                Tags <i>- optional</i>{' '}
              </span>
            }
            description="Add tags to help others find the function more easily."
            placeholder="Select tag(s)"
            keepOpen={true}
            options={getBlockTagsListForSelection()}
          />
          <TextareaFormField
            name={CAPABILITY_SUMMARY}
            label={
              <span>
                Description <i>- optional</i>{' '}
              </span>
            }
            description="Add a brief description for the function."
            constraintText="Maximum 500 characters"
          />
        </SpaceBetween>
      </Container>
      <Container header={<Header variant="h2">Input</Header>}>
        <SpaceBetween direction="vertical" size="xl">
          <SpaceBetween direction="vertical" size="s">
            <CheckboxFormField
              name={HAS_NO_CAPABILITY_INSTANCE}
              label={<span>Instance</span>}
              description="Specify the instance details for the function."
              checkboxDescription="The function doesn’t have any instance."
              defaultValue={false}
            />
            <CodeEditorFormField
              name={CAPABILITY_INSTANCE}
              language="json"
              ace={ace}
              loading={loading}
              i18nStrings={CODE_EDITOR_I18N_STRINGS}
              onPreferencesChange={null}
              defaultValue={null}
              editorContentHeight={100}
            />
          </SpaceBetween>
          <SpaceBetween direction="vertical" size="s">
            <CheckboxFormField
              name={HAS_NO_CAPABILITY_INPUT}
              label={<span>Input Parameters</span>}
              description="Specify the input arguments for the function."
              checkboxDescription="The function doesn’t have any input arguments."
              defaultValue={false}
            />
            <CodeEditorFormField
              name={CAPABILITY_INPUT}
              language="json"
              ace={ace}
              loading={loading}
              i18nStrings={CODE_EDITOR_I18N_STRINGS}
              onPreferencesChange={null}
              defaultValue={null}
            />
          </SpaceBetween>
        </SpaceBetween>
      </Container>
      <Container header={<Header variant="h2">Output</Header>}>
        <SpaceBetween direction="vertical" size="s">
          <CheckboxFormField
            name={HAS_NO_CAPABILITY_OUTPUT}
            label={<span>Output</span>}
            description="Specify the output arguments for the function."
            checkboxDescription="The function doesn’t have any output arguments."
            defaultValue={false}
          />
          <CodeEditorFormField
            name={CAPABILITY_OUTPUT}
            language="json"
            ace={ace}
            loading={loading}
            i18nStrings={CODE_EDITOR_I18N_STRINGS}
            onPreferencesChange={null}
            defaultValue={null}
          />
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
}
