import { Button, Form, SpaceBetween } from '@cloudscape-design/components';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getMyBlockLibraryDetailPagePath } from '../../../../common/page-route-path';
import { BlockDetailTabId, CapabilityType, LoadingStatus } from '../../../../common/types/enums';
import { IFunctionConfigMap } from '../../../../common/types/interfaces';
import { createCapability, selectCreateCapabilityResponse } from '../../../../slices/CapabilityDetailSlice';
import { AppDispatch } from '../../../../store/store';
import { CreateCapabilityFormProps, CreateCapabilitySchemaType } from '../config/capability-form.config';
import {
  CAPABILITY_HANDLER,
  CAPABILITY_INPUT,
  CAPABILITY_INSTANCE,
  CAPABILITY_NAME,
  CAPABILITY_OUTPUT,
  CAPABILITY_SUMMARY,
  HAS_NO_CAPABILITY_INPUT,
  HAS_NO_CAPABILITY_INSTANCE,
  HAS_NO_CAPABILITY_OUTPUT,
  TAGS,
} from '../constants/form-constants';
import MyBlockFunctionRegisterContainer from './MyBlockFunctionRegisterContainer';

export default function MyBlockFunctionRegisterForm() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const createCapabilityFormMethods = useForm<CreateCapabilitySchemaType>(CreateCapabilityFormProps);
  const createCapabilityResult = useSelector(selectCreateCapabilityResponse);

  function handleSaveChangeButton() {
    return async () => {
      const formFieldValues = createCapabilityFormMethods.getValues();

      const configMapInput: IFunctionConfigMap['input'] = {
        params: [],
      };
      if (!formFieldValues[HAS_NO_CAPABILITY_INPUT]) {
        configMapInput.params = JSON.parse(formFieldValues[CAPABILITY_INPUT]).params || [];
      }
      if (!formFieldValues[HAS_NO_CAPABILITY_INSTANCE]) {
        configMapInput.instance = JSON.parse(formFieldValues[CAPABILITY_INSTANCE]);
      }

      const createCapabilityRequest = {
        blockId: params.blockId,
        capabilityName: formFieldValues[CAPABILITY_NAME],
        capabilityType: CapabilityType.FUNCTION,
        summary: formFieldValues[CAPABILITY_SUMMARY],
        tags: formFieldValues[TAGS]?.length ? formFieldValues[TAGS] : null,
        allowedClientIds: ['CLID#PUBLIC'],
        capabilityConfigMap: {
          handler: {
            functionHandler: formFieldValues[CAPABILITY_HANDLER],
          },
          input: configMapInput,
          output: !formFieldValues[HAS_NO_CAPABILITY_OUTPUT] ? JSON.parse(formFieldValues[CAPABILITY_OUTPUT]) : null,
        },
      };

      await createCapabilityFormMethods.handleSubmit(() => dispatch(createCapability(createCapabilityRequest)))();
    };
  }

  return (
    <Form
      actions={
        <SpaceBetween direction="horizontal" size="xl">
          <Button
            formAction="none"
            variant="link"
            onClick={() => navigate(getMyBlockLibraryDetailPagePath(params.blockId, BlockDetailTabId.FUNCTIONS))}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSaveChangeButton()}
            loading={createCapabilityResult.status === LoadingStatus.PENDING}
            disabled={createCapabilityResult.status === LoadingStatus.PENDING}
          >
            Create
          </Button>
        </SpaceBetween>
      }
      errorText={createCapabilityResult.error}
      variant="embedded"
    >
      <FormProvider {...createCapabilityFormMethods}>
        <MyBlockFunctionRegisterContainer />
      </FormProvider>
    </Form>
  );
}
