export const CAPABILITY_NAME = 'capabilityName';
export const CAPABILITY_HANDLER = 'capabilityHandler';
export const CAPABILITY_SUMMARY = 'summary';
export const TAGS = 'tags';
export const CAPABILITY_INSTANCE = 'capabilityInstance';
export const HAS_NO_CAPABILITY_INSTANCE = 'hasNoCapabilityInstance';
export const CAPABILITY_INPUT = 'capabilityInput';
export const HAS_NO_CAPABILITY_INPUT = 'hasNoCapabilityInput';
export const CAPABILITY_OUTPUT = 'capabilityOutput';
export const HAS_NO_CAPABILITY_OUTPUT = 'hasNoCapabilityOutput';
