import {
  Badge,
  ColumnLayout,
  Container,
  Grid,
  Header,
  SpaceBetween,
  StatusIndicator,
  Textarea,
} from '@cloudscape-design/components';
import React from 'react';
import { useSelector } from 'react-redux';

import { CAPABILITY_STATUS_CONFIG } from '../../../../common/constants/capability-constants';
import { IFunctionConfigMap } from '../../../../common/types/interfaces';
import { convertDateToISOWithTimeZone } from '../../../../common/utils';
import BoxElement from '../../../../components/polaris/BoxElement';
import { selectGetCapabilityResponse } from '../../../../slices/CapabilityDetailSlice';

export default function MyBlockFunctionViewPageContainer() {
  const { capabilityDetail } = useSelector(selectGetCapabilityResponse).data;
  const capabilityConfigMap = { ...(capabilityDetail.capabilityConfigMap as IFunctionConfigMap) };
  // TODO: need to remove this once the back fill is completed
  if (!capabilityConfigMap.input) {
    capabilityConfigMap.input = {
      params: [],
    };
  }

  return (
    capabilityDetail && (
      <SpaceBetween size="xl">
        <Container header={<Header variant="h2">Basic Information</Header>}>
          <ColumnLayout borders="vertical" columns={3}>
            <BoxElement headerText="Resource name" description={capabilityConfigMap.handler.functionHandler} />
            <BoxElement headerText="Current version" description={capabilityDetail.capabilityVersion} />
            <BoxElement
              headerText="Status"
              description={
                <StatusIndicator
                  iconAriaLabel=""
                  type={CAPABILITY_STATUS_CONFIG[capabilityDetail.capabilityStatus].statusIndicator}
                >
                  {CAPABILITY_STATUS_CONFIG[capabilityDetail.capabilityStatus].statusText}
                </StatusIndicator>
              }
            />
            <BoxElement headerText="Minimum supported version" description={capabilityDetail.minArtifactVersion} />
            <BoxElement headerText="Maximum supported version" description={capabilityDetail.maxArtifactVersion} />
            <BoxElement
              headerText="Tags"
              description={
                capabilityDetail.tags ? (
                  <SpaceBetween direction="horizontal" size="xxs">
                    {capabilityDetail.tags.map((tag) => (
                      <Badge key={tag}>{tag}</Badge>
                    ))}
                  </SpaceBetween>
                ) : (
                  '-'
                )
              }
            />
            <BoxElement headerText="Created by" description={capabilityDetail.createdBy} />
            <BoxElement headerText="Updated by" description={capabilityDetail.updatedBy} />
            <BoxElement
              headerText="Created on"
              description={convertDateToISOWithTimeZone(new Date(capabilityDetail.createdAt))}
            />
            <BoxElement
              headerText="Last updated on"
              description={convertDateToISOWithTimeZone(new Date(capabilityDetail.updatedAt))}
            />
          </ColumnLayout>
        </Container>
        <Container header={<Header variant="h2">Description</Header>}>
          <BoxElement headerText="" description={capabilityDetail.summary || '-'} />
        </Container>
        <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
          <Container header={<Header variant="h2">Input</Header>}>
            <SpaceBetween size="l">
              <BoxElement
                headerText="Instance"
                description={
                  <Textarea
                    rows={
                      capabilityConfigMap.input.instance
                        ? JSON.stringify(capabilityConfigMap.input.instance, null, 2).split('\n').length + 1
                        : 1
                    }
                    value={
                      capabilityConfigMap.input.instance
                        ? JSON.stringify(capabilityConfigMap.input.instance, null, 2)
                        : '-'
                    }
                    readOnly={true}
                  />
                }
              />
              <BoxElement
                headerText="Parameters"
                description={
                  <Textarea
                    rows={
                      capabilityConfigMap.input.params?.length
                        ? JSON.stringify({ params: capabilityConfigMap.input.params }, null, 2).split('\n').length + 1
                        : 1
                    }
                    value={
                      capabilityConfigMap.input.params?.length
                        ? JSON.stringify({ params: capabilityConfigMap.input.params }, null, 2)
                        : '-'
                    }
                    readOnly={true}
                  />
                }
              />
            </SpaceBetween>
          </Container>
          <Container header={<Header variant="h2">Output</Header>}>
            <Textarea
              rows={
                capabilityConfigMap.output
                  ? JSON.stringify(capabilityConfigMap.output, null, 2).split('\n').length + 1
                  : 1
              }
              value={capabilityConfigMap.output ? JSON.stringify(capabilityConfigMap.output, null, 2) : null}
              readOnly={true}
            />
          </Container>
        </Grid>
      </SpaceBetween>
    )
  );
}
